import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AndroidBuildComponent } from './build/android-build/android-build.component';
import { ConnectivityComponent } from './connectivity/connectivity.component';
import { Authguard } from './guards/authguard.service';
import { Tourguard } from './guards/tourguard.service';
import { LoginComponent } from './login/login.component';
import { PushNotificationsComponent } from './addons/push-notifications/push-notifications.component';
import { AppleSettingsComponent } from './settings/apple-settings/apple-settings.component';
import { ApplicationSettingsComponent } from './settings/application-settings/application-settings.component';
import { ContentSettingsComponent } from './settings/content-settings/content-settings.component';
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component';
import { TemplateViewComponent } from './templates/template-view/template-view.component';
import { ViewBuilderComponent } from './view-builder/view-builder.component';
import { IosBuildComponent } from './build/ios-build/ios-build.component';
import { BuildStatusComponent } from './build/build-status/build-status.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { ViewsComponent } from './views/views.component';
import { AdminComponent } from './admin/admin/admin.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AppPreviewComponent } from './pages/app-preview/app-preview.component';
import { CloudSubsComponent } from './admin/admin-analytics/cloud/cloud-subs/cloud-subs.component';
import { CloudComponent } from './admin/admin-analytics/cloud/cloud.component';
import { SelfComponent } from './admin/admin-analytics/self/self.component';
import { AdminauthGuard } from './guards/adminauth.guard';
import { ExpirationGuard } from './guards/expiration.guard';
import { LogsComponent } from './admin/admin-analytics/logs/logs.component';
import { AnalyticsPageStatsComponent } from './pages/analytics/analytics-page-stats/analytics-page-stats.component';
import { ExpiredComponent } from './pages/expired/expired.component';
import { AnalyticsOrdersComponent } from './pages/analytics/analytics-orders/analytics-orders.component';
import { WebviewGuard } from './guards/webview.guard';
import { SignupComponent } from './signup/signup.component';
import { SiteTypeComponent } from './pages/site-type/site-type.component';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MixpanelService } from './tracking/mixpanel.service';
import { LtdComponent } from './pages/ltd/ltd.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { AppsUploadedComponent } from './admin/admin-analytics/apps-uploaded/apps-uploaded.component';
import { AllAppsComponent } from './admin/admin-analytics/all-apps/all-apps.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { BillingComponent } from './pages/billing/billing.component';
import { BuilderService } from './builder-services/builder.service';
import { HelpComponent } from './pages/help/help.component';
import { NewProductComponent } from './e-commerce/products/new-product/new-product.component';
import { ProductsComponent } from './e-commerce/products/list/products.component';
import { CategoriesComponent } from './e-commerce/categories/list/categories.component';
import { ShippingComponent } from './e-commerce/shipping/shipping.component';
import { SettingsComponent } from './e-commerce/settings/settings.component';
import { PostsListComponent } from './posts/posts-list/posts-list.component';
import { PostsService } from './blocks-wrapper/block-posts/posts.service';
import { PostsCategoriesComponent } from './posts/posts-categories/posts-categories.component';
import { NewPostComponent } from './posts/new-post/new-post.component';
import { OrdersComponent } from './e-commerce/orders/orders.component';
import { SingleOrderComponent } from './e-commerce/orders/single-order/single-order.component';
import { StacksImporterComponent } from './e-commerce/stacks-importer/stacks-importer.component';
import { SubscribersListComponent } from './subscribers/subscribers-list/subscribers-list.component';
import { NewSubscriberComponent } from './subscribers/new-subscriber/new-subscriber.component';
import { MobileViewComponent } from './pages/mobile-view/mobile-view.component';
import { TemplatesNewComponent } from './templates/templates-new/templates-new.component';
import { MultilingualComponent } from './translations/multilingual/multilingual.component';
import { TxtTranslationsComponent } from './translations/txt-translations/txt-translations.component';
import { ProductTranslationsComponent } from './translations/product-translations/product-translations.component';
import { CategoryTranslationComponent } from './translations/category-translation/category-translation.component';
import { PointsComponent } from './plugins/points/components/points.component';
import { SocialLoginComponent } from './addons/social-login/social-login.component';
import { PluginsServiceService } from './plugins-loader/plugins-service.service';
import { AddonsComponent } from './addons/addons/addons.component';
import { AddCouponComponent } from './e-commerce/coupons/add-coupon/add-coupon.component';
import { CouponsComponent } from './e-commerce/coupons/coupons/coupons.component';
import { FirebaseSettingsComponent } from './settings/firebase-settings/firebase-settings.component';
import { AndroidApkComponent } from './build/android-apk/android-apk.component';

const routes: Routes = [
{
  path: '',
  component: ViewBuilderComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'globals',
  component: ViewBuilderComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'login',
  component: LoginComponent,
},
{
  path: 'signup',
  component: SignupComponent,
},
{
  path: 'generalsettings',
  component: GeneralSettingsComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'firebasesettings',
  component: FirebaseSettingsComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'applicationsettings',
  component: ApplicationSettingsComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'contentsettings',
  component: ContentSettingsComponent,
  canActivate: [Authguard, Tourguard, ExpirationGuard]
},
{
  path: 'applesettings',
  component: AppleSettingsComponent,
  canActivate: [Authguard, Tourguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'templates_old',
  component: TemplateViewComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'templates',
  component: TemplatesNewComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'pushnotifications',
  component: PushNotificationsComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard]
},
{
  path: 'android',
  component: AndroidBuildComponent,
  canActivate: [Authguard, ExpirationGuard, Tourguard]
},
{
  path: 'android-apk',
  component: AndroidApkComponent,
  canActivate: [Authguard, ExpirationGuard, Tourguard, AdminauthGuard]
},
{
  path: 'ios',
  component: IosBuildComponent,
  canActivate: [Authguard, ExpirationGuard, WebviewGuard, Tourguard]
},
{
  path: 'build-status',
  component: BuildStatusComponent,
  canActivate: [Authguard, ExpirationGuard, Tourguard]
},
{
  path: 'connectivity',
  component: ConnectivityComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard]
},
{
  path: 'changelog',
  component: ChangelogComponent,
},
{
  path: 'views',
  component: ViewsComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard]
},
{
  path: 'admin',
  component: AdminComponent,
  canActivate: [Authguard, AdminauthGuard, ExpirationGuard]
},
{
  path: 'projects',
  component: ProjectsComponent,
  canActivate: [Authguard]
},
{
  path: 'app-preview',
  component: AppPreviewComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'analytics/page-stats',
  component: AnalyticsPageStatsComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard]
},
{
  path: 'analytics/orders',
  component: AnalyticsOrdersComponent,
  canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard]
},
{
  path: 'admin/self',
  component: SelfComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'admin/cloud',
  component: CloudComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'admin/logs',
  component: LogsComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'admin/cloud-subs',
  component: CloudSubsComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'admin/apps-uploaded',
  component: AppsUploadedComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'admin/all-apps',
  component: AllAppsComponent,
  canActivate: [AdminauthGuard]
},
{
  path: 'expired',
  component: ExpiredComponent,
  canActivate: [Authguard]
},
{
  path: 'site-type',
  component: SiteTypeComponent,
},
{
  path: 'ltd',
  component: LtdComponent,
  canActivate: [Authguard]
},
{
  path: 'versions',
  component: VersionsComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'payment',
  component: PaymentComponent,
  canActivate: [Authguard]
},
{
  path: 'payment_success',
  component: PaymentSuccessComponent,
  canActivate: [Authguard]
},
{
  path: 'payment_success/:token/:details/:id',
  component: PaymentSuccessComponent,
  canActivate: [Authguard]
},
{
  path: 'payment_success/:token/:package/:projectId/:priceID',
  component: PaymentSuccessComponent,
  canActivate: [Authguard]
},
{
  path: 'billing',
  component: BillingComponent,
  canActivate: [Authguard]
},
{
  path: 'help',
  component: HelpComponent,
  canActivate: [Authguard]
},
{
  path: 'products',
  component: ProductsComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'social',
  component: SocialLoginComponent,
  canActivate: [Authguard]
},
{
  path: 'multilingual',
  component: MultilingualComponent,
  canActivate: [Authguard]
},
{
  path: 'txtTranslations',
  component: TxtTranslationsComponent,
  canActivate: [Authguard]
},
{
  path: 'productTranslations',
  component: ProductTranslationsComponent,
  canActivate: [Authguard]
},
{
  path: 'categoryTranslations',
  component: CategoryTranslationComponent,
  canActivate: [Authguard]
},
{
  path: 'products/new-product',
  component: NewProductComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'products/new-product/:productId',
  component: NewProductComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'categories/categories',
  component: CategoriesComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'coupons',
  component: CouponsComponent,

},
{
  path: 'coupons/add-coupon',
  component: AddCouponComponent,

},
{
  path: 'coupons/add-coupon/:id',
  component: AddCouponComponent,

},
{
  path: 'shipping',
  component: ShippingComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'e-commerce/settings',
  component: SettingsComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'posts/list',
  component: PostsListComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'posts/new-post',
  component: NewPostComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'posts/categories',
  component: PostsCategoriesComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'orders',
  component: OrdersComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'orders/single-order/:orderId',
  component: SingleOrderComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'stacks-importer',
  component: StacksImporterComponent,
  canActivate: [Authguard, ExpirationGuard, Tourguard]
},
{
  path: 'admin/subList',
  component: SubscribersListComponent,
  canActivate: [Authguard]
},
{
path: 'admin/newSub',
component: NewSubscriberComponent,
canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'admin/newSub/:subId',
  component: NewSubscriberComponent,
  canActivate: [Authguard, ExpirationGuard]
},
{
  path: 'mobile-trials',
  component: MobileViewComponent,
  canActivate: [Authguard]
},
{
  path: 'admin/subList',
  component: SubscribersListComponent,
  canActivate: [Authguard]
},
{
path: 'admin/newSub',
component: NewSubscriberComponent,
canActivate: [Authguard]
},
{
  path: 'admin/newSub/:subId',
  component: NewSubscriberComponent,
  canActivate: [Authguard]
},
{
  path: 'plugins',
  component: AddonsComponent,
  canActivate: [Authguard]
},
{
  path: '*',
  component: ViewBuilderComponent,
  canActivate: [Authguard, Tourguard]
}
];

const pluginPaths = [
  'points',
  // 'another-module'
];

let moduleMap:any = {
  'points': () => import('./plugins/points/points.module').then(m => m.PointsModule),
  // 'another-module': () => import('../plugins/another-module/another-module.module').then(m => m.AnotherModule),
  // Add more mappings as needed
};
@NgModule({
  imports: [
    RouterModule.forChild(PluginsServiceService.getRoutesFromPaths(pluginPaths, moduleMap)),
    RouterModule.forRoot(routes)
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(private router: Router, public mixPanel: MixpanelService, private builderService: BuilderService, private pluginsService: PluginsServiceService) {

    this.router.events.subscribe(route_event => {
      if (route_event instanceof NavigationEnd) {
        this.builderService.isListShown = true;
        this.builderService.toggleSidebar = false;
        this.mixPanel.init(this.builderService.selectedProject);
        this.mixPanel.track('Page View', {
          url: route_event.url,
        });  
      }
    });
  }
}
