import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { BlockDataService } from '../../block-services/block-data.service';
import { CategoriesService } from '../block-categories/categories.service';
import { PostsService } from '../block-posts/posts.service';
import { ProductsService } from '../block-products/products.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { ProductsService as standaloneProductsService } from '../../e-commerce/products/products.service';
import { CategoriesService as standaloneCategoriesService } from '../../e-commerce/categories/categories.service';
@Component({
  selector: 'block-image',
  templateUrl: './block-image.component.html',
  styleUrls: ['./block-image.component.scss']
})
export class BlockImageComponent implements OnInit {

  previewBlock = false;
  styleFields = false;
  blockData : any;

  previewFields = false;

  blockImageSrc = "";
  blockFields: any;

  blockId = '';

  @Input() nestedBlock: any = {};
  @Input() importedBlockData: any;
  storedCategories: any = [];
  storedPosts: any = [];
  storedProducts: any = [];
  constructor(private blockDataService: BlockDataService, private blockFieldsService: BlockFieldsService, public builderService: BuilderService,
    private productsService: ProductsService,
    public categoriesService: CategoriesService,
    public standaloneProductsService: standaloneProductsService,
    public standaloneCategoriesService: standaloneCategoriesService,
    private postsService: PostsService,
    private mixPanel: MixpanelService ) {
  };

  ngOnInit() {
    if(this.importedBlockData ) {
      this.previewBlock = true;
      this.previewFields = false;
      this.styleFields = false;
    }
    if (this.nestedBlock.type == "image") {
      this.blockData = this.nestedBlock;
      this.blockId = this.nestedBlock.id;
      this.previewBlock = true;
    } else {
      this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getImageBlockFields();
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2);
      }
    }
    this.blockImageSrc = this.blockData.data.src;

    if( this.categoriesService.storedCategories.length == 0 ) {
      this.getCategories(this.builderService.getSelectedProject(), this.blockFieldsService.categoriesBlockData.data, true);
    }
    
    this.getPosts(this.builderService.getSelectedProject(), this.blockFieldsService.postsBlockData.data, true);

    this.getProducts(this.builderService.getSelectedProject(), this.blockFieldsService.productsBlockData.data, true);

    this.builderService.showSettingsProgress = false;

    this.getStandaloneProducts();
    this.getStandaloneCategories();
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockImageData = {
      component: BlockImageComponent,
      index: 'latest',
      element: this,
      initial: initial
    };
    this.blockDataService.sendBlockData(blockImageData);
    this.builderService.blocksCollapsed = true;
  }

  appendBlock($event: any) {
    if(this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true;
    }
    let clonedBlockData = { ...this.blockData };
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData);
    this.addBlockToPreview();

    if( this.categoriesService.storedCategories.length == 0 ) {
      this.getCategories(this.builderService.getSelectedProject(), this.blockFieldsService.categoriesBlockData.data, false);
    }
    
    this.getPosts(this.builderService.getSelectedProject(), this.blockFieldsService.postsBlockData.data, false);

    this.getProducts(this.builderService.getSelectedProject(), this.blockFieldsService.productsBlockData.data, false);

    this.builderService.logData('Image Block Added', 'Block Add', 'Image Block Added').subscribe((response) => {

    }, (err) => {

    });

    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'image',
    });
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId();
    }
    this.blockFieldsService.clickedBlockId = blockId;
    this.blockDataService.sendCurrentBlock( BlockImageComponent );
  }

  processFile(fileInput: any, type: string, name: string, blockId: string) {
    const file: File = fileInput.target.files[0];
    this.builderService.showSettingsProgress = true;
    if(this.builderService.standalone) {
      this.builderService.uploadImage(file, this.builderService.selectedProject, 'custom-images')?.subscribe((response) => {

        this.builderService.showSettingsProgress = false;
        let imgBlockFields = this.blockDataService.getSelectedBlockData(blockId);
        /**
         * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
         */
        let clonedBlockFieldsData = { ...imgBlockFields.data };
        clonedBlockFieldsData.src = response;
        imgBlockFields.data = clonedBlockFieldsData;

        this.builderService.showSettingsProgress = false;
      });
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.builderService.uploadFileCustomerServer(file, this.builderService.selectedProject, type).subscribe(
          (res) => {
            this.builderService.showSettingsProgress = false;
            let imgBlockFields = this.blockDataService.getSelectedBlockData(blockId);
            /**
             * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
             */
            let clonedBlockFieldsData = { ...imgBlockFields.data };
            clonedBlockFieldsData.src = res;
            imgBlockFields.data = clonedBlockFieldsData;
          },
          (err) => {
            this.builderService.showSettingsProgress = false;
            alert("error uploading file, please contact support");
          })
      });

      reader.readAsDataURL(file);
    }
    
  }
  /**
   * get all the image block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  imgSrcChange($event: any, blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    }

    clonedBlockFieldsData[style] = data;
    textBlockFields.style = clonedBlockFieldsData;
  }

  getBlockFields = (id: string) => {
    if(this.importedBlockData) {
      return this.importedBlockData;
    }
    return this.blockDataService.getSelectedBlockData(id);
  }

  showStyleTab = () => {
    this.previewBlock = false;
    this.previewFields = false;
    this.styleFields = true;
  }

  showDataTab = () => {
    this.previewBlock = false;
    this.previewFields = true;
    this.styleFields = false;
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true;
    }
    return false;
  }

  checkData = () => {
    if (this.previewFields) {
      return true;
    }
    return false;
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true;
    }
    return false;
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true;
    }
    return false;
  }

 /** 
 * Deletes the Block from the mobile Preview
 * Deletes the Block from the JSON Object
 */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId);
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return;
    }
    let buttonBlockFields = this.blockDataService.getSelectedBlockData(blockId);
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...buttonBlockFields.data };
    let data = '';
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value;
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px';
    } else if (type == 'sliderPercentage') {
      data = $event.value + '%';
    }

    clonedBlockFieldsData[query] = data;
    buttonBlockFields.data = clonedBlockFieldsData;
    if( data == 'posts') {
      this.getPosts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false);
    }

    if( data == 'products') {
      this.getProducts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false);
    }

    if( data == 'products' && this.builderService.standalone) {
      this.getStandaloneProducts()
    }
    if( data == 'productsCategories' && this.builderService.standalone) {
      this.getStandaloneCategories();
    }
  }

  getStandaloneProducts = () => {
    this.standaloneProductsService.getProducts().subscribe((response_products: any) => {
      let products: any = [];
      for (const key in response_products) {
        if (Object.prototype.hasOwnProperty.call(response_products, key)) {
          const element = response_products[key];
          products.push({ 'id': element.Id, "name": element.productName});
        }
      }
      this.standaloneProductsService.storedStandaloneProducts = products;
    });
  }
  getStandaloneCategories = () => {
    this.standaloneCategoriesService.getCategories().subscribe((response: any) => {
      let categories: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          categories.push({ 'id': element.categoryId, "name": element.categoryName, "thumbnail": element.categoryImage });
        }
      }
      this.standaloneCategoriesService.storedStandaloneCategories = categories;
    })
  }

  getCategories = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if( cached ) {
      this.categoriesService.getCategoriesCached().subscribe((response) => {
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
            }
          }
        }
        this.builderService.showSettingsProgress = false;
        this.categoriesService.storedCategories = categories;
      }, (err) => {
  
      });
    } else {
      this.categoriesService.getCategories(projectId, args).subscribe((response) => {
        let categories: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element.count > 0) {
              categories.push({ 'id': element.term_id, "name": element.name, "thumbnail": element.image_url });
            }
          }
        }
        this.builderService.showSettingsProgress = false;
        this.categoriesService.storedCategories = categories;
      }, (err) => {
  
      });
    }
    
    return true;
  }

  getPosts = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if(cached) {
      this.postsService.getPostsCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image});
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {
  
      });
    } else {
      this.postsService.getPosts(projectId, args).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image});
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {
  
      });
    }
    return true;
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if( cached ) {
      this.productsService.getProductsCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name});
            }
          }
        }
        this.storedProducts = products;
      }, (err) => {
  
      });
    } else {
      this.productsService.getProducts(projectId, args).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name});
            }
          }
        }
        this.storedProducts = products;
      }, (err) => {
  
      });
    }
    return true;
  }

  /**
   * Refetch the Data of the Block from the customer server and refills the localstorage
   */
   refetchData = () => {
    this.getProducts(this.builderService.getSelectedProject(), this.blockData.data, false);
    this.categoriesService.getInitialCategories();
   }
}
