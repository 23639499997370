import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LoginService } from 'src/app/login/login.service';
import { AppSettingsService } from '../services/app-settings.service';

@Component({
  selector: 'app-firebase-settings',
  templateUrl: './firebase-settings.component.html',
  styleUrl: './firebase-settings.component.scss',
})
export class FirebaseSettingsComponent {
  showProgress = false;
  appleSettingsData: any = {};
  isGoogleServiceJsonUploading: boolean = false;
  isPlistUploading: boolean = false;
  plistFile: string = '';
  googleServiceJsonFile: string = '';

  firebaseSettingsForm = this.formBuilder.group({
    stacks_user_apiKey: '',
    stacks_user_authDomain: '',
    stacks_user_databaseURL: '',
    stacks_user_projectId: '',
    stacks_user_storageBucket: '',
    stacks_user_messagingSenderId: '',
    stacks_user_appId: '',
    stacks_user_measurementId: '',
    plist_filepath: '',
    google_filepath: '',
  });

  firebaseSettingsData: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService
  ) {
    this.builderService.showUpdateProgress = true;
    // Wait for sometime till the project id is retrieved from the server if it is not available yet
    let project_id = this.builderService.getSelectedProject();
    if (!project_id) {
      setTimeout(() => {
        this.getInitialSettings();
      }, 1000);
    } else {
      this.getInitialSettings();
    }
  }

  getInitialSettings = () => {
    let project_id = this.builderService.getSelectedProject();
    this.firebaseSettingsData = this.appSettingsService
      .getFirebaseSettings(project_id)
      .subscribe(
        (settings: any) => {
          console.log('firebase settings ', settings);
          this.plistFile = settings.plist_filepath;
          this.googleServiceJsonFile = settings.google_filepath;
          this.firebaseSettingsData = settings !== null ? settings : {};
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          console.log(err);
        }
      );
  };
  ngOnInit(): void {}

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;

    /**
     * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
     */
    let newSubmittedData: any = {};
    let submittedData: any = this.firebaseSettingsForm.value;
    let retrievedData = this.firebaseSettingsData;
    for (var k in submittedData) {
      if (submittedData[k] != '') {
        newSubmittedData[k] = submittedData[k];
      } else {
        newSubmittedData[k] = retrievedData[k];
      }
    }

    this.appSettingsService
      .setFirebaseSettings(
        this.builderService.selectedProject,
        newSubmittedData
      )
      .subscribe(
        (response) => {
          this.builderService.showUpdateProgress = false;
        },
        (err) => {}
      );
  }

  generalSettingsNext = () => {
    this.onSubmit();
    if (this.builderService.projectAccess == 'webview') {
      this.builderService.setTour(false).subscribe(
        (response) => {
          this.router.navigate(['/contentsettings'], {
            relativeTo: this.route,
          });
        },
        (err) => {}
      );
    } else {
      this.router.navigate(['/site-type'], { relativeTo: this.route });
    }
  };

  processFile(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService
        .uploadFile(file, this.builderService.selectedProject, type, name)
        .subscribe(
          (res) => {
            this.builderService.showUpdateProgress = false;
            if (name === 'google_filepath') {
              this.googleServiceJsonFile = file.name;
            } else if (name === 'plist_filepath') {
              this.plistFile = file.name;
            }
          },
          (err) => {
            this.builderService.showUpdateProgress = false;
            alert('error uploading file, please contact support');
          }
        );
    });

    reader.readAsDataURL(file);
  }

  clearFileName(name: string) {
    if (name === 'google_filepath') {
      this.googleServiceJsonFile = '';
      this.firebaseSettingsForm
        .get('google_filepath')
        ?.setValue(this.googleServiceJsonFile);
    } else if (name === 'plist_filepath') {
      this.firebaseSettingsForm.get('plist_filepath')?.setValue(null);
      this.plistFile = '';
    }
  }
}
