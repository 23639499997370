import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpersService } from '../helpers.service';
import { Observable } from 'rxjs';
import { DatabaseService } from '../builder-services/database.service';


@Injectable({
  providedIn: 'root'
})
export class ViewsService {

  allViews: any = [
    {"view_id":1,"view_name":"home","title":"home", "all_blocks": ""},
    {"view_id":2,"view_name":"categoryView","title":"category", "all_blocks": ""},
    {"view_id":3,"view_name":"singleView","title":"single", "all_blocks": ""},
    {"view_id":4,"view_name":"loginView","title":"login", "all_blocks": ""},
    {"view_id":5,"view_name":"signupView","title":"signup", "all_blocks": ""},
    {"view_id":6,"view_name":"forgotView","title":"forgot", "all_blocks": ""},
    {"view_id":7,"view_name":"myAccountView","title":"myaccount", "all_blocks": ""},
    {"view_id":8,"view_name":"singleBlogPostView","title":"single blog post", "all_blocks": ""},
    {"view_id":9,"view_name":"cartView","title":"cart", "all_blocks": ""},
    {"view_id":10,"view_name":"aboutView","title":"about", "all_blocks": ""},
    {"view_id":11,"view_name":"contactView","title":"contact", "all_blocks": ""},
    {"view_id":12,"view_name":"termsView","title":"terms", "all_blocks": ""},
    {"view_id":13,"view_name":"checkoutView","title":"checkout", "all_blocks": ""},
    {"view_id":14,"view_name":"singleCategoryView","title":"single category", "all_blocks": ""},
  ];
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };

  private builderUrl;

  constructor( private http: HttpClient, public helpers: HelpersService, private db: DatabaseService) { 
    this.builderUrl = this.helpers.getBuilderUrl();
  }

  submitView = (view_name: string, project_id: any, view_id: any = '', all_blocks: any = '') => {
    // let postData = new URLSearchParams();
    // postData.set('project_id', project_id);
    // postData.set('view_name', view_name);
    // view_id     !== '' ? postData.set('view_id', view_id) : '';
    // all_blocks  !== '' ? postData.set('all_blocks', all_blocks) : '';
    let data = { 
      'project_id'        : project_id,
      'all_blocks'        : all_blocks,
      'view_id'           : view_id,
      'view_name'         : view_name
    }
    let options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    return this.http.post(this.builderUrl + 'submit-view', JSON.stringify(data), options);
    // return this.http.post(this.builderUrl + 'submit-view', postData.toString(), this.options);
  }

  // submitCustomerView = (view_name: string, project_id: any, view_id: any = '', all_blocks: any = '') => {
  submitCustomerView = (project_id: any, data: any) => {
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + project_id + '/views/', data).subscribe((response) => {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      }, (error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }




  getStandaloneViews = (project_id: any) => {
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/views/').subscribe((response) => {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      }, (error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }
  getViews = (project_id: any, get_all_views:any = '') => {
    let postData = new URLSearchParams();
    postData.set('project_id', project_id);
    get_all_views !== '' ? postData.set('get_all_views', get_all_views) : '';

    return this.http.post(this.builderUrl + 'get-custom-views', postData.toString(), this.options);
  }

  editView = (view_name: string, project_id: any,  view_id: any) => {
    let postData = new URLSearchParams();
    postData.set('project_id', project_id);
    postData.set('view_name', view_name);
    postData.set('view_id', view_id);

    return this.http.post(this.builderUrl + 'edit-view', postData.toString(), this.options);
  }

  deleteView = (project_id: any, view_id: any) => {
    let postData = new URLSearchParams();
    postData.set('project_id', project_id);
    postData.set('view_id', view_id);

    return this.http.post(this.builderUrl + 'delete-view', postData.toString(), this.options);
  }

  allViewsFormatter = (allData:any, standalone: boolean) => {
    this.allViews = [];
    let project_id = localStorage.getItem('selectedProject');
    for (const key in allData) {
      if (Object.prototype.hasOwnProperty.call(allData, key)) {
        let count = Number(key);
        const element = allData[count];
        let element_data = element.data ? element.data : element.allBlocks;
        if(!standalone) {
          this.allViews.push({'view_id':element.view_id, 'view_name':element.view_name, 'data': JSON.parse(element_data)})
        } else {
          let data = { 
            'projectId'     : project_id,
            'allBlocks'     : element_data,
            'view_id'        : element.view_id,
            'view_name'      : element.view_name
          }
          this.allViews.push(data)
        }
        
      }
    }
  }
}
