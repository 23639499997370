<form [formGroup]="settingsForm" id="appSettingsWrapper" class="settingsWrapper" (ngSubmit)="onSubmit()">
  <h1 *ngIf="!this.builderService.getTour()">{{'General Settings' | translate  }}</h1>
  <button *ngIf="this.builderService.getTour()" class="tourNextActionUpper stacksBtn green" type="button" (click)="generalSettingsNext()">
    {{'Next' | translate }}
  </button>
  <div *ngIf="this.builderService.getTour()" class="generalSettingsTourHeader">
    <h1>{{'Initial Setup' | translate }}</h1>
    <p>{{'You can leave the below settings' | translate }}</p>
  </div>
  <ul>
    <li>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{'Application Name' | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{'Click to know more' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{'This is the name of the app that shows on your customers phones' | translate }}</p>
      </mat-expansion-panel>
      <mat-form-field appearance="outline">
        <mat-label>{{'Application Name' | translate }}</mat-label>
        <input matInput placeholder="{{'Pizza Store' | translate}}" formControlName="application_name"
          value="{{this.generalSettingsData?.application_name}}">
      </mat-form-field>
    </li>
    <li>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{'Package Name' | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{'Click to know more' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{'The package name is the identifier' | translate }}</p>
      </mat-expansion-panel>
      <mat-form-field appearance="outline">
        <mat-label>{{'Package Name Format should be' | translate }}</mat-label>
        <input matInput placeholder="com.pizzastore.www" formControlName="package_name"
          pattern="^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$" value="{{this.generalSettingsData?.package_name}}">
      </mat-form-field>
    </li>
    <!-- <li *ngIf="!this.builderService.getTour()">
      <h2>Application Description</h2>
      <p>This description is for building process only and it will not be published to your store accounts. (can be
        updated later). Max 200 Characters.</p>
      <mat-form-field class="example-full-width">
        <mat-label>Application Description</mat-label>
        <textarea rows="4" matInput formControlName="application_description"
          placeholder="Great Application for Ordering
          Pizza">{{this.generalSettingsData?.application_description}}</textarea>
      </mat-form-field>
    </li> -->
    <li>
    <mat-expansion-panel *ngIf="!this.builderService.getTour()" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>{{'IPA SKU Number' | translate }}</h2>
        </mat-panel-title>
        <mat-panel-description>
          {{'Click to know more' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>{{'Required for IOS Applications The SKU can contain letters' | translate }}</p>
    </mat-expansion-panel>
      <mat-form-field appearance="outline">
        <mat-label>{{'IPA SKU Number' | translate }}</mat-label>
        <input matInput formControlName="ipa_sku_number" placeholder="12345678" type="number"
          value="{{this.generalSettingsData?.ipa_sku_number}}">
      </mat-form-field>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2> {{'Splash Screen' | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{'Click to know more' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{'This is the image that appears' | translate }}</p>
      </mat-expansion-panel>
      <div>
      <span class="imageDimensions">{{'Size: 2732*2732' | translate }}</span>
      <span class="imageDimensions">{{'Extensions: .png' | translate }}</span>
      </div>
      <div class="mainMobilePreviewShape">
        <div class="mobileAppPreview">
          <img class="splashScreen" src="{{this.generalSettingsData?.splash_screen}}" />
        </div>
      </div>
      <div>
        <label *ngIf="!this.builderService.getTour()" class="fileUpload" for="splashScreen">
          {{'Splash Screen' | translate }}
          <input type="file" accept="image/png" formControlName="splash_screen" id="splashScreen"
            (change)="this.processFile($event, 'splash_screen', 2732, 2732)">
        </label>
      </div>
    </li>
    <li>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{'Icon' | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{'Click to know more' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{'Application Icon.
          The image shouldn\'t contain transparent areas as it\'s a restriction from Apple App Store.' | translate }}
          </p>
      </mat-expansion-panel>
      <div>
        <span class="imageDimensions">
          {{'Size: 1024*1024' | translate }}
          </span>
        <span class="imageDimensions">
          {{'Extensions: .png' | translate }}
          </span>
      </div>
        <div class="mainMobilePreviewShape">
          <div class="mobileAppPreview">
            <img src="{{this.generalSettingsData?.icon}}" />
          </div>
        </div>
        <div>
          <label *ngIf="!this.builderService.getTour()" class="fileUpload" for="appIcon">
            {{'App Icon' | translate }}
            <input type="file" formControlName="icon" accept="image/png" id="appIcon"
              (change)="this.processFile($event, 'icon', 1024,1024)">
          </label>
        </div>
      </li>
    <li *ngIf="loginService.getCurrentUser().admin">
      <h2>Release App</h2>
      <p>Admins can choose whether to release a debug or release app</p>
      <mat-form-field appearance="outline">
        <mat-label>App Type</mat-label>
        <mat-select formControlName="release_app">
          <mat-option value="yes">
            Release
          </mat-option>
          <mat-option value="no">
            Debug
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle color="primary" [(ngModel)]="isSampleEnabled" [ngModelOptions]="{ standalone: true }" (change)="toggleSample()">Enable Sample</mat-slide-toggle>
      <p *ngIf="newSamplePw">Current Password: <strong>{{ newSamplePw }}</strong></p>
    </li>
  </ul>
  <button *ngIf="this.builderService.getTour()" id="tourNextAction" type="button" (click)="generalSettingsNext()">
    {{'Next' | translate }}
  </button>
</form>