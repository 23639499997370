import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LoginService } from 'src/app/login/login.service';
import { AppSettingsService } from '../services/app-settings.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  selectedFile!: ImageSnippet;
  isSampleEnabled: boolean = false;
  project_id: string;
  submittedData: any;
  showProgress = false;
  newSamplePw: string | null = null;
  settingsForm = this.formBuilder.group({
    application_name: '',
    package_name: 'com.stacks.www',
    ipa_sku_number: '',
    application_description: 'Description',
    icon: '',
    splash_screen: '',
    release_app: '',
  });

  fileToUpload: File | null = null;

  generalSettingsData : any = {};
  constructor(
    private formBuilder: FormBuilder,
    private db: DatabaseService,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService,
  ) { 
    this.builderService.showUpdateProgress = true;
    // Wait for sometime till the project id is retrieved from the server if it is not available yet
    this.project_id = this.builderService.selectedProject;
      this.getInitialSettings();
  }

  getInitialSettings = () => {
    this.db.getDatabase(`/projects/${this.project_id}/settings/general_settings`).subscribe(
      (settings) => {
        this.generalSettingsData = settings !== null ? settings : {};
        if (settings !== null) {
          this.settingsForm.patchValue({
            application_name: this.generalSettingsData.application_name || '',
            package_name: this.generalSettingsData.package_name || this.settingsForm.value.package_name,
            ipa_sku_number: this.generalSettingsData.ipa_sku_number || '',
            application_description: this.generalSettingsData.application_description || 'Description',
            icon: this.generalSettingsData.icon || '',
            splash_screen: this.generalSettingsData.splash_screen || '',
            release_app: this.generalSettingsData.release_app || '',
          });
        }        
        this.newSamplePw = settings.samplesPw;
        if (!settings.samplesPw) {
          this.generateRandomPassword(6);
        }
        this.isSampleEnabled = settings.isSampleEnabled || false;
        this.builderService.showUpdateProgress = false;
      }, (err) => {
        console.log(err);
      });
  }

  generateRandomPassword(length: number): string {
    if (this.newSamplePw && this.newSamplePw.trim().length > 0) {
      return this.newSamplePw;
    }
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.newSamplePw = result;
    return result;
  } 

  toggleSample(): void {
    this.isSampleEnabled = this.isSampleEnabled;
  }


  ngOnInit(): void {}

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;
    let icon = this.settingsForm.value.icon;
    let splash_screen = this.settingsForm.value.splash_screen;
    /**
     * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
     */
      let newSubmittedData:any = {};
      this.submittedData = this.settingsForm.value;
      let retrievedData = this.generalSettingsData;
      for (var k in this.submittedData) {
        if (this.submittedData[k] != "") {
          newSubmittedData[k] = this.submittedData[k];
        } else {
          newSubmittedData[k] = retrievedData[k];
        }
      }
    /**
     * Step 1 Submit the Icons and Splash Screens
     */
    if (!icon ) {
      newSubmittedData['icon'] = this.generalSettingsData.icon;
    }
    if (!splash_screen) {
      newSubmittedData['splash_screen'] = this.generalSettingsData.splash_screen;
    }

    newSubmittedData['isSampleEnabled'] = this.isSampleEnabled;
    newSubmittedData['samplesPw'] = this.newSamplePw;

    this.appSettingsService.setGeneralSettings(this.builderService.selectedProject, newSubmittedData).subscribe( (response) => {
    this.updateAuthRedirect();
      this.builderService.showUpdateProgress = false;
    }, (err) => {

    });
  }

  processFile(imageInput: any, type: string, minWidth: number, minHeight: number) {
    const file: File = imageInput.target.files[0];
    const reader = new FileReader();
    this.builderService.showUpdateProgress = true;
    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.appSettingsService.uploadImage(this.selectedFile.file, this.builderService.selectedProject, type ).subscribe(
        (res) => {
          if (type == 'icon' || type == 'splash_screen') {
            /**
             * Validate the image width and height
             */
              const img = new Image();
              let $this = this;
              img.src = res;
              img.addEventListener("load", function () {
                if (this.width < minWidth || this.height < minHeight) {
                  alert("Min image Dimensions = " + minWidth + " x " + minHeight + ", Uploaded Dimensions = " + this.width + " x " + this.height);
                } else {
                  $this.generalSettingsData[type] = res;
                }

              });
          } else {
            this.generalSettingsData[type] = res;
          }
          
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }

  generalSettingsNext = () => {
    this.onSubmit();
    if( this.builderService.projectAccess == 'webview' ) {
      this.builderService.setTour(false).subscribe((response) => {
        this.router.navigate(['/contentsettings'], { relativeTo: this.route });
      }, (err) => {
  
      });
      
    } else {
      this.router.navigate(['/site-type'], { relativeTo: this.route });
    }
  }

  updateAuthRedirect() {
    this.builderService.showUpdateProgress = true;
        this.appSettingsService.getApplicationSettings(this.project_id).subscribe(
          (currentSettings: any) => {
            const packageName =
            this.submittedData.package_name || this.settingsForm.value.package_name;
            const auth0_domain =
              currentSettings.auth0_domain ||
              'dev-3t3cfn38mfbo482t.us.auth0.com';
            const auth0_redirect_uri = `${packageName}://${auth0_domain}/capacitor/${packageName}/callback`;
            
            const updatedSettings = {
              ...currentSettings,
              auth0_redirect_uri: auth0_redirect_uri,
            };

            this.appSettingsService
              .setApplicationSettings(this.project_id, updatedSettings)
              .subscribe(() => {
                this.appSettingsService
                  .setWPApplicationSettings(
                    this.builderService.selectedProject,
                    updatedSettings
                  )
                  .subscribe(() => {
                    console.log(updatedSettings);
                    this.builderService.showUpdateProgress = false;
                  });
              });
          },
          (error: any) => {
            console.error('Error saving social login data', error);
          }
        );
  }
}