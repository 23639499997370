import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { BuilderService } from '../builder-services/builder.service';
import { SidebarService } from './sidebar.service';

export interface MenuItem {
  name: string;
  icon: string;
  route: string;
};

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {


  menus: MenuItem[] = [];
  constructor( public loginService: LoginService, public builderService: BuilderService, private sidebarService: SidebarService) {

    this.sidebarService.getMenuItems().subscribe((data) => {
      this.menus = data;
    });
   }

  mobile = false;
  appDropdown = false;
  commerceDropdown = false;

  // sidebarCollapsed = true;
  environment = environment;
  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  toggleSideMenu(){
    this.builderService.sidebarOpened = !this.builderService.sidebarOpened;
    this.commerceDropdown = false;
    this.appDropdown = false;
  }

  toggleListedCommerce(){
    this.builderService.sidebarOpened = true;
    this.commerceDropdown = !this.commerceDropdown;
  }
  toggleGenerate(){
    this.builderService.sidebarOpened = true;
    this.appDropdown = !this.appDropdown;
  }

  toggleSidebar(className: string, element: HTMLElement) {
    this.builderService.sidebarOpened = true;
    element.classList.toggle(className);
  }

  // sidebarHome = () => {
  //   this.sidebarCollapsed = !this.sidebarCollapsed;
  // }
  isAdmin(): boolean{
    if (this.loginService.getCurrentUser().admin){
      return true;
    } 
    return false;
  }
}
