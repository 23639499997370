import { BuilderService } from 'src/app/builder-services/builder.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-multilingual',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatTableModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
  templateUrl: './multilingual.component.html',
  styleUrls: ['./multilingual.component.scss'],
})
export class MultilingualComponent implements OnInit {
  defaultLanguage: string = '';
  language: { title: string; isNew?: boolean }[] = [];
  languagesToDelete: string[] = [];
  multilingual!: FormGroup;
  newLanguage: string = '';
  productProgress: boolean = false;

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadLanguages();
    this.initForm();
  }

  initForm(): void {
    this.multilingual = this.fb.group({
      languages: this.fb.array([]),
    });
  }

  loadLanguages(): void {
    const project_id = this.builderService.selectedProject;
    const languagesPath = `/projects/${project_id}/translations/languages`;
    const defaultLangPath = `/projects/${project_id}/translations`;

    this.db.getDatabase(languagesPath).subscribe((existingLanguages: any) => {
      if (existingLanguages) {
        this.language = Object.keys(existingLanguages).map((title) => ({
          title,
        }));
      } else {
        console.warn('No languages data found.');
        this.language = [];
      }
      this.refreshTable();
    });

    this.db.getDatabase(defaultLangPath).subscribe(
      (defaultLang: any) => {
        this.defaultLanguage = defaultLang?.defaultLanguage || '';
      },
      (error) => {
        console.error('Error retrieving default language:', error);
        this.defaultLanguage = '';
      }
    );
  }

  addRow(): void {
    this.language.push({ title: '', isNew: true });
    this.refreshTable();
  }

  finalizeLanguage(lang: { title: string; isNew?: boolean }): void {
    if (
      lang.title.trim() &&
      !this.language.some(
        (existingLang) =>
          existingLang.title === lang.title && existingLang !== lang
      )
    ) {
      lang.isNew = false;
      this.refreshTable();
    } else {
      alert('Please enter a unique language name or language already exists.');
      this.language = this.language.filter(
        (existingLang) => existingLang !== lang
      );
      this.refreshTable();
    }
  }

  deleteRow(rowTitle: string): void {
    if (this.language.length > 1) {
      const isConfirmed = confirm(
        `Are you sure you want to delete "${rowTitle}"?`
      );
      if (isConfirmed) {
        this.languagesToDelete.push(rowTitle);
        this.language = this.language.filter((lang) => lang.title !== rowTitle);
        this.refreshTable();
      }
    } else {
      alert('At least one language must be present.');
    }
  }

  saveLanguages(): void {
    this.productProgress = true;
    let project_id = this.builderService.selectedProject;
    const languagesPath = `/projects/${project_id}/translations/languages`;

    const capitalizeFirstLetter = (input: string): string => {
      if (!input) return ''; // Handle empty or null strings
      return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    };

    interface Languages {
      [key: string]: string;
    }

    this.db.getDatabase(languagesPath).subscribe(
      (existingLanguages: Languages | null) => {
        if (existingLanguages) {
          this.languagesToDelete.forEach((langToDelete) => {
            const formattedLangToDelete = capitalizeFirstLetter(langToDelete);
            delete existingLanguages[formattedLangToDelete];
          });

          this.languagesToDelete = [];

          const newLanguages: Languages = this.language.reduce(
            (acc: Languages, curr) => {
              const formattedTitle = capitalizeFirstLetter(curr.title);
              if (!(formattedTitle in existingLanguages)) {
                acc[formattedTitle] = '';
              }
              return acc;
            },
            {}
          );

          this.db
            .setDatabase(languagesPath, {
              ...existingLanguages,
              ...newLanguages,
            })
            .subscribe(
              (response) => {
                if (response) {
                  console.log('Languages saved successfully.');
                }
                this.productProgress = false;
              },
              (error) => {
                console.error('Error saving languages:', error);
                this.productProgress = false;
              }
            );
        }
      },
      (error) => {
        console.error('Error retrieving existing languages:', error);
        this.productProgress = false;
      }
    );

    // Save the default language
    const defaultLangPath = `/projects/${project_id}/translations`;
    this.db
      .setDatabase(defaultLangPath, { defaultLanguage: this.defaultLanguage })
      .subscribe(
        (response) => {
          if (response) {
            console.log('Default language saved successfully.');
            this.loadLanguages();
          }
        },
        (error) => {
          console.error('Error saving default language:', error);
        }
      );
  }

  selectDefaultLanguage(lang: string): void {
    this.defaultLanguage = lang;
    console.log(`Default language selected: ${this.defaultLanguage}`);
  }

  refreshTable() {
    this.language = [...this.language];
  }

  onSubmit(): void {
    this.saveLanguages();
  }
}
