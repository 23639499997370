import { Injectable } from '@angular/core';
import { BuilderService } from '../../builder-services/builder.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersService } from '../../helpers.service';
import { DatabaseService } from '../../builder-services/database.service';
import { Observable } from 'rxjs';
import { OrdersService } from 'src/app/e-commerce/orders/orders.service';

export interface TypeObject {
  type: string;
  message: string; // Ensure message is always present, but it can be an empty string initially.
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  nameOptions: string[] = ['User Action', 'Order Status'];
  project_id = this.builderService.getSelectedProject();


  typeOptions: { [key: string]: TypeObject[] } = {
    'User Action': [{ message: '', type: 'Created' }, { message: '', type: 'Deleted' }],
    'Order Status': [
      { message: '', type: 'Pending' },
      { message: '', type: 'Order Received' },
      { message: '', type: 'Processing' },
      { message: '', type: 'Completed' },
      { message: '', type: 'Cancelled' },
      { message: '', type: 'Refunded' },
      { message: '', type: 'Failed' },
      { message: '', type: 'On-Hold' },
      { message: '', type: 'Trash' }
    ]
  };
  userList: any = []
  deviceIDs: any = [];

  private builderUrl: string;
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;

  constructor(private builderService: BuilderService, private helper: HelpersService, private http: HttpClient,
    private databaseService: DatabaseService,
    private ordersService: OrdersService) {
    this.builderUrl = this.helper.getBuilderUrl();
  }
  /**
   * Get all the users of the Customer website
   * @param id the Project ID
   */
  getUsers = (id: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.databaseService.getDatabase('projects/' + this.builderService.selectedProject + '/users').subscribe((response: any) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete();
        });
      })
    } else {
      this.token = this.builderService.getToken();
      let postData = new URLSearchParams();
      postData.set('token', this.token);
      postData.set('projectId', id);
      return this.http.post(this.builderUrl + 'get-users', postData.toString(), this.options);
    }
  }
  /**
   * 
   * @param title The title of the Notification
   * @param message The Message of the Notification
   * @param users Comma Separated List of the users
   * @param route The route to be executed when the Notification is received
   * @param specificRoute The specific route to be executed when the Notification is received
   * @returns 
   * Send Push Notification
   */
  sendMessage = (title: string, message: string, users: any, route: any = '', specificRoute: any = '') => {
    console.log(users)
    let routing
    let productId;
    this.deviceIDs = []
    for (let i of users) {
      this.deviceIDs.push(i.device_id)
    }
    if(specificRoute){
      if(route === "products"){
      routing  = "/product-single"
      productId = specificRoute
      }
    }else{
      routing = route
    }
    console.log(this.token)
    console.log(productId)
    let notificationData: any = {
     "deviceTokens": this.deviceIDs,
      // notification: {
        "title": title,
        "body": message,
      // },
      "data": {
        "page": routing,
        "productId": productId,
        "specificRoute": specificRoute
      }
    }
    let options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    console.log(this.deviceIDs)
    return this.http.post('https://app.stacksmarket.co/send-notification', notificationData, options)
  }

  /**
   * Validates that the fields of the push notifications are saved correctly
   * @param id project id
   */
  validatePN = (id: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('projectId', id);
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'validate-notifications', postData.toString(), this.options);
  }

  // automate(name: string) {
  //   console.log(name)
  //   if (this.checkAvailability(name)) {
  //     this.databaseService.getDatabase('projects/' + this.project_id + '/automation').subscribe((data) => {
  //       console.log(data)
  //       for (let i = 0; i < data.length; i++) {
  //         if (data[i].selectedType.type === name) {
  //           for (let j = 0; j < this.ordersService.orderData.length; j++) {
  //             console.log(this.ordersService.orderData[j].user_id)
  //             let uid = this.ordersService.orderData[j].user_id
  //             this.getUsers(this.project_id).subscribe((users: any) => {
  //               console.log(users)
  //               for (let k in users) {
  //                 if (users.hasOwnProperty(uid)) {
  //                   console.log("Key is " + k + ", value is " + users[i]);
  //                   if (k === uid) {
  //                     this.userList.push(users[k])
  //                     console.log("they are equal")
  //                     console.log(this.userList)
  //                     this.sendMessage(this.project_id, "Your Order", data[i].selectedType.message, this.userList)
  //                   }
  //                 }
  //               }
  //             })
  //           }
  //         }
  //         this.databaseService.setDatabase('projects/' + this.project_id + '/automation', data).subscribe((response) => {
  //         })
  //       }
  //     })
  //   }
  // }

  checkAvailability(name: string) {
    for (let i of this.typeOptions["Order Status"]) {
      if (i.type === name) {
        return true
      }
    }
    return false
  }
}
