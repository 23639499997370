<div cdkDropListGroup id="mobileWidgetsWrapper">
  <blocks-wrapper *ngIf="!this.mobile && !builderService.getTour()" [ngClass]=" builderService.globals ? 'hideBlocks' : '' " cdkDropList cdkDropListSortingDisabled></blocks-wrapper>
  <div id="mobileWrapper">
    <div class="howItWorks noselect" (click)="howItWorksDialog()" *ngIf="builderService.globals">
      <span class="material-icons-outlined">play_circle_filled</span>
        {{'How it works' | translate }}
    </div>
    <app-stepper step="6" *ngIf="builderService.getTour() && !builderService.globals"></app-stepper>
    <button class="stacksBtn blue startDesigningTour glow" (click)="startDesigningTour()" *ngIf="builderService.getTour() && !builderService.onboardingHomeStarted">{{'End Tour' | translate}}</button>
    <div id="mainMobilePreviewShape" *ngIf="!builderService.getTour()">
      <mobile-preview class="mobileAppPreview" 
        id="mainMobileAppPreview" 
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="getColumnsIds()"
        >
      </mobile-preview>
      <div *ngIf="!this.mobile">
        <span id="firstMobBtn"  class="mobBtns"></span>
        <span id="secondMobBtn" class="mobBtns"></span>
        <span id="thirdMobBtn"  class="mobBtns"></span>
        <span id="fourthMobBtn" class="mobBtns"></span>
      </div>
    </div>
  </div>
  <!-- The architecture of the mobile needs the blocks wrapper to be after the main mobile preview -->
  <blocks-wrapper [ngClass]="builderService.blocksCollapsed ? 'collapsedParentBlocks' : 'openedParentBlocks'" *ngIf="this.mobile && !builderService.globals && !builderService.getTour()" cdkDropList cdkDropListSortingDisabled></blocks-wrapper>
  <button mat-icon-button (click)="blocksHome()" class="blocksHomeMobile" [ngClass]="[builderService.blocksCollapsed ? '' : 'openedButton', builderService.getTour() ? 'glowingBlueElement' : '']" *ngIf="mobile && !builderService.getTour()">
    <mat-icon class="material-icons material-icons-outlined">grid_view</mat-icon>
  </button>
</div>
<div id="settingsTemplatesWrapper" [ngClass]="openSettings ? 'openedSettings' : ''" *ngIf="!builderService.getTour()">
  <div *ngIf="mobile" class="closeSettings" (click)="closeSettings()" [ngClass]="builderService.getTour() ? 'glowingGreenElement' : ''">
    <mat-icon>close</mat-icon>
  </div>
  <block-settings id="blockSettings"></block-settings>
  <global-settings id="globalSettings" *ngIf="builderService.globals"></global-settings>
  <templates *ngIf="loginService.getCurrentUser().admin && !builderService.globals" id="createTemplates"></templates>
  <search-projects *ngIf="loginService.getCurrentUser().admin && !builderService.globals" id="searchProjects"></search-projects>
  <pre id="debuggableJSON" *ngIf="debuggableJSON && !this.mobile">{{builderService.globalSettings | json}}</pre>
  <pre id="debuggableJSON" *ngIf="debuggableJSON && !this.mobile">{{blockDataService.getAllBlocksData() | json}}</pre>
</div>

<guided-tour *ngIf="!builderService.globals && !builderService.getTour() && !mobile"></guided-tour>